import React from "react"
import Grid from "../components/grid"
import { Link } from "gatsby"


export default function About(){
    return(
    <Grid>
        <p></p>
        <Link to='/'>hello</Link>
        <Link to='/'>I'm</Link>
        <Link to='/'>Zach</Link>
    </Grid>
    )
}